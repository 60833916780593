<template>
  <v-row style="background-color: #fefefe;">
    <!--<fragment class="d-none d-sm-flex d-sm-none d-md-flex">-->
    <v-col
      lg="7"
      md="7"
      cols="12"
      class="fondo"
    >
      <!--<section
          id="faq"
          class="mt-5"
        >
          <v-card-text class="question-section">
            <v-row>
              <v-col
                v-for="item in faqQiestions"
                :key="item.title"
                md="10"
                offset-md="2"
                cols="12"
                class="mx-auto mb-8 questions"
              >
                <div class="faq-title d-flex align-center">
                  <v-avatar
                    color="secondary"
                    rounded
                    size="40"
                    class="v-avatar-light-bg"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>

                  <div class="ms-4">
                    <h2 class="text-xl font-weight-medium">
                      {{ item.title }}
                    </h2>
                    <p class="mb-0">
                      {{ item.subtitle }}
                    </p>
                  </div>
                </div>

                !--<div class="faq-question-answers mt-6">
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="data in item.qAndA"
                      :key="data.question"
                    >
                      <v-expansion-panel-header class="text-base">
                        {{ data.question }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{ data.answer }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>--
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="text-center">
            <v-row>
              <v-col
                md="10"
                offset-md="2"
                cols="12"
                class="mx-auto mb-8 questions"
              >
                <p class="text-xl font-weight-medium mb-1">
                  Asistencia 24h
                </p>
              </v-col>
            </v-row>
            !--<p>If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!</p>--
          </v-card-text>

          <v-row class="mx-auto">
            <v-col
              cols="12"
              sm="6"
              md="5"
              class="ms-auto questions"
            >
              <v-card
                class="faq-contact text-center pa-5"
                flat
              >
                <v-avatar
                  rounded
                  color="primary"
                  size="38"
                  class="v-avatar-light-bg mb-6"
                >
                  <v-icon
                    color="primary"
                    size="24"
                  >
                    {{ icons.mdiPhone }}
                  </v-icon>
                </v-avatar>

                <p
                  class="text-xl mb-2"
                  style="color: #fff;"
                >
                  +53 5 2679908<br />
                  +53 5 3329892
                </p>

                !--<p class="text-sm mb-0">
                  We are always happy to help!
                </p>--
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="5"
              class="me-auto questions"
            >
              <v-card
                class="faq-contact text-center pa-5"
                flat
              >
                <v-avatar
                  rounded
                  color="primary"
                  size="38"
                  class="v-avatar-light-bg mb-6"
                >
                  <v-icon
                    color="primary"
                    size="24"
                  >
                    {{ icons.mdiEmailOutline }}
                  </v-icon>
                </v-avatar>

                <p class="text-xl font-weight-medium mb-2">
                  <br /><a style="color: #fff;">booking@holiplus.com</a>
                </p>
              </v-card>
            </v-col>
          </v-row>
        </section>-->
    </v-col>
    <!--</fragment>-->

    <v-col
      lg="5"
      md="5"
      sm="12"
      xs="12"
      cols="12"
    >
      <FormLogin />
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiHelpCircleOutline,
  mdiCurrencyUsd,
  mdiBriefcaseVariantOutline,
  mdiPhone,
  mdiEmailOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'

import FormLogin from './FormLogin.vue'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiPhone,
        mdiEmailOutline,
      },
    }
  },

  components: {
    FormLogin,
  },

  data() {
    return {
      faqQiestions: [
        {
          title: 'Te ayudamos a elegir lo mejor',
          subtitle: 'Gran variedad de destinos para satisfacer todos los presupuestos.',
          icon: mdiHelpCircleOutline,

          /* qAndA: [
            {
              question: 'General settings',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
            {
              question: 'Users',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
            {
              question: 'Personal data',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
            {
              question: 'Advanced settings',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
          ], */
        },
        {
          title: 'Atención especializada',
          subtitle: 'En cualquier lugar, desde el momento en que reservas.',
          icon: mdiCurrencyUsd,

          /* qAndA: [
            {
              question: 'Does my subscription automatically renew?',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
            {
              question: 'Can I store the item on an intranet so everyone has access?',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
            {
              question: 'What does non-exclusive mean?',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
            {
              question: 'Is the Regular License the same thing as an editorial license?',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
          ], */
        },
        {
          title: 'Garantía de compra',
          subtitle: 'Garantizamos disponibilidad en tiempo real y confirmación inmediata.',
          icon: mdiBriefcaseVariantOutline,

          /* qAndA: [
            {
              question: 'Can I avail of an open delivery?',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
            {
              question: 'I haven’t received the refund of my returned shipment. What do I do?',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
            {
              question: 'How can I ship my order to an international location?',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
            {
              question: 'I missed the delivery of my order today. What should I do?',
              answer:
                'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
            },
          ], */
        },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
@import '@core/preset/preset/pages/faq.scss';
</style>
<style>
.fondo {
  background-image: url('../../assets/login/background.webp');
  /*height: 770px;*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 600px) {
  .fondo {
    display: none;
  }
}
.questions {
  background: rgba(148, 144, 144, 0.7);
  color: #fff;
  border-radius: 5px;
}
</style>
