<template>
  <LoginTrav v-if="name_project === 'ttttt'" />
  <LoginAll v-else />
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'
// eslint-disable-next-line import/no-unresolved
import LoginTrav from '@/views/login/LoginTrav.vue'
// eslint-disable-next-line import/no-unresolved
import LoginAll from '@/views/LoginAll.vue'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    // eslint-disable-next-line camelcase
    const name_project = process.env.VUE_APP_PROJ_NAME
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      name_project,
      email,
      password,
      socialLink,

      // themeConfig
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },

  components: {
    LoginTrav,
    LoginAll,
  },
}
</script>
